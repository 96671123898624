// src/pages/Checkout/Checkout.tsx
import React, { useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "./PaymentForm";
import CheckoutForm from "./CheckoutForm";
import { CheckoutEvent } from "../../admin/auth/types";
import { configStripeCheckout } from "../../admin/auth/aws-config";
import { Container, Box, Typography } from "@mui/material";
import CheckoutNavbar from "../../Components/Appbar/CheckoutNavBar";

const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_KEY || "");
console.log("Stripe Promise Initialized:", !!stripePromise);

const Checkout: React.FC = () => {
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleCheckout = async (event: CheckoutEvent) => {
    console.log("Checkout event payload:", event);

    try {
      const response = await fetch(configStripeCheckout.API_ENDPOINT_STRIPE_CHECKOUT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(event),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || "Failed to create PaymentIntent.");
      }

      if (data.client_secret) {
        setClientSecret(data.client_secret);
      } else {
        throw new Error("Client secret not returned from backend.");
      }
    } catch (error: any) {
      console.error("Error creating PaymentIntent:", error);
      setError(error.message);
      alert(error.message);
    }
  };

  return (
    <Container maxWidth="md" sx={{pt: 8}}>
      <CheckoutNavbar />
      <Box sx={{ mt: 4 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Checkout
        </Typography>
        {error && (
          <Typography variant="body1" color="error" align="center">
            {error}
          </Typography>
        )}
        {!clientSecret ? (
          <CheckoutForm onCheckout={handleCheckout} />
        ) : (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <PaymentForm />
          </Elements>
        )}
      </Box>
    </Container>
  );
};

export default Checkout;
